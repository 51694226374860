<template>
  <b-row class="root-row">
    <b-col>
      <b-card class="payments p-3">
        <b-row class="mb-4">
          <b-col>
            <span class="card-title">Facturación</span>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-tabs content-class="py-4 px-3">
              <b-tab
                title="Cuentas"
                :active="$route.query.tab === 'cuentas'"
                @click="changeTab('cuentas')"
              >
                <admin-billings />
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import LoadingSpinner from "@zubut/common/src/components/LoadingSpinner";

const AdminBillings = () => ({
  component: import("./AdminBillings/AdminBillings"),
  loading: LoadingSpinner
});

export default {
  components: {
    AdminBillings
  },

  methods: {
    changeTab(tab) {
      this.$router.push({ name: "invoices", query: { tab } }).catch(() => {});
    }
  }
};
</script>
